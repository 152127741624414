import { Box, Paper, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ImageComp from "../../../components/globalComponents/ImageComp";
import HeroSection from "../../../components/globalComponents/HeroSection";
import Dropdown from "../../../components/globalComponents/Dropdown";
import Layout from "../../../components/globalComponents/Layout/Layout";
import SearchInput from "../../../components/globalComponents/global_inputs/SearchInput";
import { top100Films } from "../../../components/data";
import ButtonComp from "../../../components/globalComponents/ButtonComp";
import theme from "../../../theme";
import useFetch from "../../../features/hooks/useFetch";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setCourseParentCategories } from "../../../features/slice/categoriesSlice";
import CourseCard from "../../../components/Services/CourseCard";
const MarketCourses = () => {
  const [flag, setFlag] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("All");
  const [filteredCourses, setFilteredCourses] = useState(null);
  const [courseCategoriesList, setCourseCategoriesList] = useState([]);
  const [parents, setParents] = useState(null);

  let dispatch = useDispatch();
  const navigate = useNavigate();
  const { fetchData } = useFetch();

  let courseCat = [];
  //function to get the course categories from the backend
  const getCourseCategories = async () => {
    await fetchData("/api/course/parent", undefined, (res) => {
      if (res) {
        res?.data?.map((category) => {
          courseCat.push(category.name);
        });
        setCourseCategoriesList((current) => {
          current = courseCat;
          return [...courseCategoriesList, ...current];
        });
        setParents(res?.data);
        dispatch(setCourseParentCategories({ data: res?.data }));
        setFlag(true);
      }
    });
  };
  //function to fetch all products
  const getAllCourses = async () => {
    await fetchData("/api/course", undefined, (res) => {
      setFilteredCourses(res?.data);
    });
  };
  //function to filter all courses if there is a selected category from dropdown
  const filterCourses = () => {
    setSelectedCategory(selectedCategory);
    let element;
    for (let index = 0; index < parents?.length; index++) {
      if (selectedCategory === parents[index].name) {
        element = parents[index];
      }
    }
    if (element) {
      fetchData(
        `/api/course?parentCategory=${element.courseParentCategoryId}`,
        undefined,
        (res) => {
          setFilteredCourses(res?.data);
        }
      );
    } else {
      getAllCourses();
    }
  };
  useEffect(() => {
    filterCourses();
  }, [selectedCategory]);
  useEffect(() => {
    getCourseCategories();
    getAllCourses();
  }, []);
  return (
    <Box>
      <HeroSection
        customStyles={{
          height: "245px",
        }}
        title={`Explore Our Diverse Courses & Create Your Own Route`}
        description={`Welcome to the varied course offerings of our school, where each subject is 
intended to empower and inspire. Our extensive curriculum of courses offers 
students with the knowledge and abilities necessary to succeed and innovate, 
whether they are pursuing new interests or enhancing prior passions. Join us in a 
journey of discovery and growth, and unlock your full potential with us.`}
      />
      <Layout title={"Skill Enhancement Zone | SMAC"}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "-30px !important",
            zIndex: 999,
          }}
        >
          <SearchInput
            dropDownData={top100Films}
            inputType="multitag"
            display={"block"}
            Icondisply={{
              display: "none",
            }}
          />
        </Box>

        <Box
          sx={{
            maxHeight: "408px",
            marginBottom: "60px",
            marginTop: "200px",
            display: "flex",
            alignItems: "flex-end",
          }}
        >
          <Box
            sx={{
              width: "100%",
              backgroundImage: "url(assets/photos/quranBackground.png)",
              height: "196px",
              padding: "0px 55px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              position: "relative",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              [theme.breakpoints.down("md")]: {
                height: "auto",
                flexDirection: "column",
                gap: "24px",
                alignItems: "center",
                justifyContent: "center",
                padding: "25px 55px",
              },
            }}
          >
            <Box
              sx={{
                position: "relative",
                bottom: "102.5px",
                [theme.breakpoints.down("md")]: {
                  display: "none",
                },
              }}
            >
              <ImageComp src={"assets/photos/QuranScholar.png"} />
            </Box>
            <Typography
              variant="quranBanner"
              sx={{
                width: "100%",
                maxWidth: "814px",
              }}
            >
              Learn <span style={{ color: "#FFCA41" }}>Quran</span> everyday add
              on your daily routine
            </Typography>
            <ButtonComp
              label={"View Courses"}
              customStyles={{
                background: "#FFCA41",
                width: "max-content",
                borderRadius: "4px",
                fontWeight: "400 !important",
              }}
              customHover={{ background: "#FFCA41", color: "white" }}
              click={() => {
                navigate("/courses/quranic");
              }}
            />
          </Box>
        </Box>
        <Box sx={{ margin: "25px 0" }}>
          {/* {JSON.stringify(courseCategoriesList)} */}
          {flag ? (
            <Dropdown
              data={courseCategoriesList}
              cb={setSelectedCategory}
              name={"course"}
            />
          ) : null}
        </Box>
        <Box
          sx={{
            margin: "0 auto",
            display: "grid",
            gap: "20px",
            gridTemplateColumns: "32% 32% 32%",
            [theme.breakpoints.down("lg")]: {
              gridTemplateColumns: "50% 50%",
            },
            [theme.breakpoints.down("md")]: {
              gridTemplateColumns: "auto",
              justifyItems: "center",
            },
            // [theme.breakpoints.down("sm")]: {
            //   gridTemplateColumns: "auto",
            //   justifyItems: "center",
            // },
          }}
        >
          {filteredCourses?.length === 0 ? (
            <Typography variant="h4Black">
              There are no courses of category {selectedCategory}
            </Typography>
          ) : (
            filteredCourses?.map((course) => {
              return <CourseCard course={course} key={course.courseId} />;
            })
          )}
        </Box>
      </Layout>
    </Box>
  );
};

export default MarketCourses;
