import React, { useEffect, useState } from "react";
import Posts from "../../components/Social/posts/Posts";
import { TabPanel, TabList, TabContext } from "@mui/lab";
import { Typography, Tab, Box } from "@mui/material";
import UploadPost from "../../components/Social/Modals/UploadPost/UploadPost";
import Layout from "../../components/globalComponents/Layout/Layout";
import UserComments from "../../components/Social/Modals/comment/UserComments";
// import Share from "../../components/Social/Modals/share/Share";
// import AddStoryBtn from "../../components/Social/Stories/AddStoryBtn";
// import AddStory from "../../components/Social/Modals/Story/AddStory/AddStory";
// import StoriesSlider from "../../components/Social/Stories/CustomStories/StoriesSlider";
import useFetch from "../../features/hooks/useFetch";
import MultiStateUserIntrests from "./../../components/User/Modals/multiStateUserIntrests/MultiStateUserIntrests";
import {
  setFollowing,
  setFollowers,
  setpendingAndFollowers,
} from "../../features/slice/followSlice";
import { useDispatch } from "react-redux";
import useInfiniteScroll from "../../features/hooks/infiniteScroll";
import { setCurrentUserInterests } from "../../features/slice/autoCompleteSlice";
import { SyncLoader } from "react-spinners";
import Explore from "./Explore";
const HomePage = () => {
  const [value, setValue] = useState("1");
  // const [open, setOpen] = useState(false);
  const [del, setDel] = useState(null);
  const [newPost, setNewPost] = useState(null);
  const [posts, setPosts] = useState([]);
  let dispatch = useDispatch();
  // const { getData } = useGetAPI();

  //yet to be use
  let { fetchScrollData, isFetching } = useInfiniteScroll();
  //api/user/post
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const { fetchData } = useFetch();
useEffect(()=>{
  console.log(newPost,"chcek new post",posts);
  if(newPost?.postAdd){

    setPosts((pre)=>[newPost.postAdd,...pre])
  }
},[newPost])
  // const [stories, setStories] = useState([]);
  // const [myStories, setMyStories] = useState([]);
  // const [userDetails, setUserDetails] = useState("");

  // const getAllStories = async () => {
  //   await fetchData("/api/user/story", undefined, (res) => {
  //     // currently only friends sstories are being hit
  //     setStories(res?.data?.friendsStories);
  //     setMyStories(res?.data?.stories);
  //     setUserDetails(res?.data?.user);
  //   });
  // };
  const getAdditionalDetails = () => {
    fetchData("/api/user/user-extradetails", undefined, (res) => {
      dispatch(
        setCurrentUserInterests({
          currentUserInterests: res?.data?.interests,
        })
      );
    });
  };
  const getAllFollowers = async (data) => {
    await fetchData(
      "/api/user/connection/getall",
      setpendingAndFollowers,
      (res) => {
        // dispatch(setpendingAndFollowers(res));
      }
    );
  };
  const filterPostData = () => {
    setPosts(
      posts.map((p) => {
        return p.postID === del ? del : p;
      })
    );
  };
  //use infinite scroll and fetch data
  useEffect(() => {
    if (!isFetching) return;
    fetchScrollData(setPosts); // <--- Pass setLocalData as an argument
  }, [isFetching]);
  
  const getAllPosts = async () => {
    fetchScrollData(setPosts);

  };
  //  hit api to get people you are following
  const getFollowing = async () => {
    fetchData("/api/user/connection/following", undefined, (res) => {
      dispatch(setFollowing(res));
      getAllPosts();
    });
  };

  const getFollowers = async () => {
    fetchData("/api/user/connection/follower", undefined, (res) => {
      // setShowFollowers(res?.data);
      dispatch(setFollowers(res));
    });
  };

  // eslint-disable-next-line
  useEffect(() => {
    // getAllFollowers() this is for get all the status of the follower
    getAllFollowers();
    getFollowers();
    getFollowing();
    // getAllStories();
  }, [newPost]);
  // here i use the filterPostData to filter the post which i am deleting...
  useEffect(() => {
    getAllFollowers();
    filterPostData();
  }, [del]);
  useEffect(() => {
    getAdditionalDetails();
  }, []);

  // const handleClickOpen = () => {
  //   setOpen(true);
  // };

  const handleCommentUpdate = (post) => {
    setPosts(
      posts.map((p) => {
        return p.postID === post.postID ? post : p;
      })
    );
  };
  return (
    <>
      <MultiStateUserIntrests />
      <UserComments updComments={handleCommentUpdate} />
      {/* <Share /> */}
      {/* <AddStory setOpen={setOpen} open={open} /> */}
      <Layout title={"SMAC | Freelance And Marketplace"}>
        {/* <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "15px",
            marginTop: "43px",
          }}
        >
          <AddStoryBtn click={handleClickOpen} />

          <StoriesSlider stories={stories} myStories={myStories} />
        </Box> */}
        <Box sx={{ width: "100%", marginTop: "50px" }}>
          <TabContext value={value}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                position: "sticky",
                top: 97,
                zIndex: 10,
                background: "#f8f8f8",
              }}
            >
              <TabList onChange={handleChange}>
                {/* tab buttons? */}
                <Tab
                  label={
                    <Typography variant="h3" sx={{ width: "200px" }}>
                      For You
                    </Typography>
                  }
                  value="1"
                />
                <Tab
                  label={
                    <Typography variant="button20" sx={{ width: "200px" }}>
                      Explore
                    </Typography>
                  }
                  value="2"
                />
              </TabList>
              <UploadPost
                cb={setNewPost}
                style={{
                  zIndex: 5,
                }}
              />
            </Box>
            <TabPanel value="1">
              <Box sx={{ padding: "40px 0px" }}>
                <Posts delCb={setDel} myposts={posts ? posts : null} />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <SyncLoader
                    color="#5AB9A6"
                    loading={isFetching}
                    margin={4}
                    size={20}
                    speedMultiplier={1}
                  />
                </div>
                {/* post button code/\ */}
              </Box>
            </TabPanel>
            <TabPanel value="2">
              <Explore />
            </TabPanel>
          </TabContext>
        </Box>
      </Layout>
    </>
  );
};

export default HomePage;
