import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import SinglePost from "../../components/Social/posts/SinglePost";
import SkeletonLoader from "../../components/globalComponents/SkeletonLoader";
import { Masonry } from "@mui/lab";
import useGetAPI from "../../features/hooks/useGetAPI";
import ButtonComp from "../../components/globalComponents/ButtonComp";
import theme from "../../theme";
import useFetch from "../../features/hooks/useFetch";
import CourseCard from "../../components/Services/CourseCard";
import ProductCard from "../../components/Market/Cards/Card";

const Explore = ({ myposts, delCb }) => {
  const { getData } = useGetAPI(); // Custom hook to get data from API
  const [posts, setPosts] = useState([]);
  const [trendingCourses, setTrendingCourses] = useState([]);
  const [trendingProducts, setTrendingProducts] = useState([]);
  const [load, setLoad] = useState(true);
  // Function to fetch data from API
  const { fetchData } = useFetch();
  const fetchScrollData = async () => {
    //this api to be changed if the the trending post api is created
    try {
      await getData(`/api/user/post?limit=6&page=1`, (response) => {
        const data = response.data;
        setPosts(data);
        setLoad(false);
      });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const fetchTrendingCourses = () => {
    //this api to be changed if the the trending post api is created
    try {
      fetchData("/api/course?limit=6", undefined, (res) => {
        setTrendingCourses(res?.data);
      });
    } catch (err) {
      console.log(err, "error while getting courses");
    }
  };
  const fetchTrendingProducts = () => {
    //this api to be changed if the the trending post api is created
    try {
      fetchData("/api/product?limit=8", undefined, (res) => {
        setTrendingProducts(res?.data);
      });
    } catch (err) {
      console.log(err, "error while getting products");
    }
  };
  useEffect(() => {
    fetchScrollData();
    fetchTrendingCourses();
    fetchTrendingProducts();
  }, []);

  return (
    <Box
      sx={{
        mt: "2rem",
        display: "flex",
        flexDirection: "column",
        gap: "2.5rem",
      }}
    >
      <Box>
        <Box>
          <Typography
            variant="userDashboardHeading"
            color={"#333333"}
            component={"div"}
          >
            Trending Posts for you to discover
          </Typography>
          <Typography variant="uploadForm">
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry.
          </Typography>
        </Box>

        <Box
          sx={{
            maxHeight: "809px",
            overflow: "hidden",
            overflowY: "scroll",
            scrollbarWidth: "none",
            mt: "2.5rem",
          }}
        >
          <Masonry
            sx={{ display: "flex", flexWrap: "wrap" }}
            columns={{ xs: 1, sm: 2, md: 3, lg: 3 }}
            spacing={11}
          >
            {load
              ? [1, 2, 3].map((post, index) => <SkeletonLoader key={index} />)
              : posts.map((post, index) => (
                  <div
                    key={post.postID}
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <SinglePost post={post} />
                  </div>
                ))}
          </Masonry>
        </Box>
      </Box>
      <Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
          }}
        >
          <Box>
            <Typography
              variant="userDashboardHeading"
              color={"#333333"}
              component={"div"}
            >
              Best Course for you!
            </Typography>
            <Typography variant="uploadForm">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry.
            </Typography>
          </Box>
          <Box sx={{ width: "149px" }}>
            <ButtonComp
              label={"View All"}
              customStyles={{
                background: "none",
                border: `1px solid ${theme.palette.primary.main}`,
                color: theme.palette.primary.main,
              }}
            />
          </Box>
        </Box>

        <Box
          sx={{
            margin: "0 auto",
            display: "grid",
            gap: "20px",
            gridTemplateColumns: "32% 32% 32%",
            mt: "2.5rem",
            gap: "1.5rem",
            [theme.breakpoints.down("lg")]: {
              gridTemplateColumns: "50% 50%",
            },
            [theme.breakpoints.down("md")]: {
              gridTemplateColumns: "auto",
              justifyItems: "center",
            },
            // [theme.breakpoints.down("sm")]: {
            //   gridTemplateColumns: "auto",
            //   justifyItems: "center",
            // },
          }}
        >
          {trendingCourses.map((course) => {
            return <CourseCard course={course} key={course.courseId} />;
          })}
        </Box>
      </Box>
      <Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
          }}
        >
          <Box>
            <Typography
              variant="userDashboardHeading"
              color={"#333333"}
              component={"div"}
            >
              Latest Listed Products!
            </Typography>
            <Typography variant="uploadForm">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry.
            </Typography>
          </Box>
          <Box sx={{ width: "149px" }}>
            <ButtonComp
              label={"View All"}
              customStyles={{
                background: "none",
                border: `1px solid ${theme.palette.primary.main}`,
                color: theme.palette.primary.main,
              }}
            />
          </Box>
        </Box>

        <Box
          sx={{
            margin: "0 auto",
            mt: "2.5rem",
            display: "grid",
            gap: "20px",
            gridTemplateColumns: "24% 24% 24% 24%",
            gridAutoRows: "462px",
            [theme.breakpoints.down("lg")]: {
              gridTemplateColumns: "32% 32% 32%",
            },
            [theme.breakpoints.down("md")]: {
              gridTemplateColumns: "49% 49%",
            },
            [theme.breakpoints.down("sm")]: {
              gridTemplateColumns: "auto",
              justifyItems: "center",
            },
          }}
        >
          {trendingProducts.map((product) => {
            return <ProductCard cardData={product} key={product.productId} />;
          })}
        </Box>
      </Box>
    </Box>
  );
};

export default Explore;
